import React from "react"
import { withPrefix } from "gatsby"
import { Helmet } from "react-helmet"

const Head = () => {
  return (
    <Helmet title="this is a test">
    </Helmet>
  )
}

export default Head
