/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"

import Header from "./header"

import Head from "./Head"

import "../../static/style.css"

import Footer from "./Footer"
import SEO from "./seo"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Toggle Menu
  const [isOpened, setIsOpened] = useState(false)

  const toggleMenu = () => {
    setIsOpened(!isOpened)
  }

  return (
    <>
      <Head />
      
      <Header isOpened={ isOpened } />

      <div className={ `redboa-burger-menu ${isOpened ? "redboa-menu-open" : ''}`} onClick={() => toggleMenu()}>
        <span>
          <div className="redboa-line-menu redboa-line-half redboa-first-line"></div>
          <div className="redboa-line-menu"></div>
          <div className="redboa-line-menu redboa-line-half redboa-last-line"></div>
        </span>
      </div>
      <div className="redboa-fixedsidebar-bg-overlay" onClick={() => toggleMenu()}></div>
      <div className="redboa-side-content">
        <div className="logo">
          <Link to="/">
            <img
              src="/images/picerija-jaka-logo.svg"
              className="img-fluid"
              alt=""
            />
          </Link>
        </div>

        {children}
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
