import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ isOpened }) => {
  const data = useStaticQuery(graphql`
    query {
      HWGraphQL {
        infoPage: page(id: "cG9zdDozMA==") {
          acfMeta {
            informacije {
              delovnicasdnevi
              delovnicasura
              telefonska
              naslov
            }
          }
        }
      }
    }
  `)
  const {
    HWGraphQL: {
      infoPage: {
        acfMeta: {
          informacije: { telefonska },
        },
      },
    },
  } = data
  return (
    <div className={ `redboa-fixed-sidebar redboa-sidebar-left ${isOpened ? "redboa-fixed-sidebar-open" : "asd"}`}>
      <div className="redboa-header-container">
        <div className="redboa-fixed-menu-wrap">
          <div className="logo">
            <Link to="/">
              <img
                src="/images/picerija-jaka-logo.svg"
                className="img-fluid"
                alt=""
              />
            </Link>
          </div>

          <nav className="redboa-menu-fixed">
            <ul>
              <li>
                <Link to="/">Domov</Link>
              </li>
              <li>
                <Link to="/o-nas">O nas</Link>
              </li>
              <li>
                <Link to="/jedilnik">Jedilnik</Link>
              </li>
              <li>
                <Link to="/galerija">Galerija</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
            <div className="redboa-footer-sidebar">
              <p>
                <span>Rezervacije</span>
              </p>
              <h6 dangerouslySetInnerHTML={{__html: telefonska }}></h6>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
